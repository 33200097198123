import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import "../../../../../src/layouts/utils/app/style.css";

const ConfigHeader = () => {
    return (
        <Fragment>
            <header className="config-header">
                <div className="config-links">
                    <NavLink to="/configuration/ble">Rfid</NavLink>
                    <NavLink to="/configuration/product">Product</NavLink>
                    <NavLink to="/configuration/category">Category</NavLink>
                    <NavLink to="/configuration/location">Location</NavLink>
                </div>
            </header>
        </Fragment>
    );
};

export default ConfigHeader;
